<template>
  <div :class="['custom-tree', className]">
    <div v-if="canSearch" class="tree-search">
      <el-input
        v-model="filterText"
        :placeholder="searchPlaceholder"
      ></el-input>
      <span class="search-icon el-icon-search"></span>
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-tree
        class="filter-tree"
        :data="treeData"
        :node-key="nodeKey"
        :highlight-current="highlight"
        :default-expanded-keys="expandedKeys"
        :current-node-key="currentKey"
        :props="defaultProps"
        :expand-on-click-node="expandOnClickNode"
        :default-expand-all="expandAll"
        :filter-node-method="filterNode"
        ref="tree"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <i :class="iconFont(data)"></i>
            <!--TODO 按data.TypeId绑定不同icon-->
            {{ node.label }}
          </span>
          <slot :id="node.id" :data="data"></slot>
        </div>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "搜索...",
    },
    nodeKey: {
      type: String,
      default: "Id",
    },
    highlight: {
      type: Boolean,
      default: true,
    },
    expandOnClickNode: {
      type: Boolean,
      default: false,
    },
    canSearch: {
      type: Boolean,
      default: true,
    },
    expandedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "Children",
          label: "Name",
        };
      },
    },
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: "",
      currentKey: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    handleNodeClick(data, node, ev) {
      console.log(node, ev);
      this.$emit("select", data);
    },
    iconFont(data) {
      if (!data) {
        return "iconfont iconjichushuju-zuzhijiagou";
      }
      switch (data.TypeId) {
        case "school":
          return "iconfont iconjichushuju-xuexiao";
        case "college":
          return "iconfont iconjichushuju-erjiyuanxiao";
        case "major":
          return "iconfont iconjichushuju-zhuanye";
        case "class":
          return "iconfont iconjichushuju-banji";
        default:
          return "iconfont iconlianxiqiyeyuanxiao";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-tree {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tree-search {
    padding: 24px;
    position: relative;
    .search-icon {
      position: absolute;
      right: 36px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  ::v-deep .el-tree-node__content {
    height: 40px;
  }
  ::v-deep.el-tree-node {
    .el-tree-node__content {
      margin: 0 24px;
      padding-right: 10px;
      box-sizing: border-box;
      &:hover {
        background-color: rgba(61, 90, 254, 0.08);
        color: #3d5afe;
        .oprate-icon {
          color: #3d5afe !important;
        }
      }
    }
  }
  ::v-deep.el-tree-node:focus > .el-tree-node__content {
    color: #3d5afe;
    background-color: rgba(61, 90, 254, 0.08);
    .oprate-icon {
      color: #3d5afe !important;
    }
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
</style>
