import request from "@/utils/request";
import request_proxy from "@/utils/request-proxy";
/**
 * 获取专业列表 GET
 * @param {*} schoolId
 */
export function getAllList() {
  return request_proxy({
    url: "/DataCenter/majorbase/GetAllList",
    method: "get"
  });
}
