<template>
    <div class="main-content" v-loading="loading">
        <div class="rc-header">
            <span class="rc-title"><i class="iconfont icon-jichushuju-banji mr-8"></i>{{ headerTitle }}</span>
            <!-- <el-button v-if="isView" size="small" type="primary" icon="el-icon-edit" @click="isView=false">
        编辑
      </el-button> -->
        </div>
        <el-form ref="classForm" class="rc-content" :model="classFormData" :rules="rules" label-width="100px">
            <el-form-item prop="MajorId" label="所属专业：">
                <el-input placeholder="" v-model="classFormData.MajorName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="Name" label="班级名称：">
                <el-input placeholder="请输入班级名称" v-model="classFormData.Name"></el-input>
            </el-form-item>
            <el-form-item prop="Grade" label="年级：">
                <el-input placeholder="请输入年级" v-model="classFormData.Grade" type="number"></el-input>
            </el-form-item>
            <el-form-item v-if="!isView">
                <el-button type="primary" @click="handleSubmit">保 存</el-button>
                <el-button @click="isView = true">取 消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { insertClass, updateClass, getClassInfo } from "@/api/classInfo";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    export default {
        props: {
            nodeData: {
                type: Object,
                default () {
                    return { Id: "", TargetId: "" };
                },
            },
            newData: {
                type: Object,
                default () {
                    return { Level: 2, Sort: 1, TargetId: "" };
                },
            },
            isCreate: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isView: true,
                loading: false,
                classId: "",
                classFormData: {},
                rules: {
                    Name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
                    MajorId: [{ required: true, message: "请选择专业", trigger: "blur" }],
                },
                isEdit: false,
                schoolId: "",
                data: {},
                nodeFormData: {},
                majorId: "",
            };
        },
        computed: {
            headerTitle() {
                var titleText = "班级信息";
                if (!this.isView) {
                    if (this.isCreate) {
                        titleText += " - 添加";
                    } else {
                        titleText += " - 修改";
                    }
                }
                return titleText;
            },
        },
        created() {
            this.initialize();
        },
        methods: {
            async initialize() {
                try {
                    this.loading = true;
                    this.isView = !this.isCreate;
                    console.log(this.isView);
                    debugger;
                    if (!this.isCreate) {
                        var res = await getClassInfo(this.nodeData.TargetId);
                        if (res.IsSuccess) {
                            this.classFormData = res.Result;
                        } else {
                            this.$message.error("获取班级信息失败", res.Message);
                        }
                    } else {
                        this.classFormData.SchoolId = this.nodeData.UnitId;
                        this.classFormData.MajorId = this.nodeData.TargetId;
                        this.classFormData.MajorName = this.nodeData.Name;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            },

            fillNodeForm(entityId) {
                if (this.isCreate) {
                    this.nodeFormData = Object.assign({}, this.newData);
                } else {
                    this.nodeFormData = this.nodeData;
                }
                this.nodeFormData.Name = this.classFormData.Name;
                this.nodeFormData.TargetId = entityId;
            },
            handleSubmit() {
                this.$refs.classForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            var res;
                            if (this.isCreate) {
                                res = await insertClass(this.classFormData);
                                this.classFormData.Id = res.Result;
                            } else {
                                res = await updateClass(this.classFormData);
                            }
                            if (res.IsSuccess) {
                                var resOrg = {};
                                this.fillNodeForm(this.classFormData.Id);
                                if (this.isCreate) {
                                    //新增专业后 新增节点关系
                                    resOrg = await insertOrganization(this.nodeFormData);
                                } else {
                                    resOrg = await updateOrganization(this.nodeFormData);
                                }
                                if (resOrg.IsSuccess) {
                                    this.$message.success("操作成功");
                                    this.isView = true;
                                    this.$emit("reload", this.nodeData);
                                } else {
                                    this.$message.error(resOrg.Message || "操作失败");
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误",
                        });
                        return;
                    }
                });
            },
        },
        watch: {
            nodeData(val) {
                this.initialize();
            },
        },
    };
</script>

<style></style>