import request from "@/utils/request";
import request_proxy from "@/utils/request-proxy";
/**
 * 获取专业列表 GET
 * @param {*} schoolId
 */
export function getMajorListBySchoolId(id) {
  return request_proxy({
    url: "/DataCenter/major/GetListBySchoolId?schoolId=" + id,
    method: "get"
  });
}
/**
 * 获取单个专业信息
 * @param {*} id
 */
export function getMajorInfo(id) {
  return request_proxy({
    url: "/DataCenter/major/GetInfo?id=" + id,
    method: "get"
  });
}

export function getAchievementTypeList(id) {
  return request_proxy({
    url: "/DataCenter/major/GetInfo?id=" + id,
    method: "get"
  });
}
/**
 * 新增专业 POST
 * @param {*} data
 */
export function insertMajor(data) {
  return request_proxy({
    url: "/DataCenter/Major/Insert",
    method: "post",
    data
  });
}
/**
 * 编辑专业 POST
 * @param {*} data
 */
export function updateMajor(data) {
  return request_proxy({
    url: "/DataCenter/Major/Update",
    method: "post",
    data
  });
}
