import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
export const CODE_MESSAGE = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误！",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "请求超时，请重试！"
};
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_TWO_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
      // let each request carry token --['X-Token'] as a custom key.
      // please modify it according to the actual situation.
      // config.headers['Content-Type']="multipart/form-data"
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  response => {
    const res = response.data;
    //
    if (res && res.StatusCode === 0) {
      return res;
    } else {
      Message.error({
        message: res.Message || "请求出错，请重试！",
        duration: 2000
      });
      return Promise.reject(res.Message || "操作失败");
    }
  },
  error => {
    if (error.response) {
      Message({
        message: CODE_MESSAGE[error.response.status],
        type: "error",
        duration: 2000
      });
    } else if (error.request) {
      Message({
        message: CODE_MESSAGE[error.request.status],
        type: "error",
        duration: 2000
      });
    } else if (
      error.code == "ECONNABORTED" &&
      error.message.indexOf("timeout") != -1
    ) {
      Message({
        message: "请求超时，请重试！",
        type: "error",
        duration: 2000
      });
    } else {
      Message({
        message: "发生意外，请重试！",
        type: "error",
        duration: 2000
      });
    }
    // var originalRequest = error.config
    // console.log(error.config)

    return Promise.reject(error);
  }
);
service.all = axios.all;
service.spread = axios.spread;
export default service;
