// import request from "@/utils/request";
import request from "@/utils/request-proxy";

/**
 *
 * @param {Form} params 获取专业列表
 */
export function getMajorbaseAllList(params) {
  return request({
    url: "/DataCenter/MajorBase/GetAllList",
    method: "get",
    params
  });
}
/**
 *
 * @param {Form} params 获取专业列表
 */
export function getMajorbasePageList(params) {
  return request({
    url: "/DataCenter/majorbase/GetPageList",
    method: "get",
    params
  });
}
/**
 *
 * @param {Form} params 新增专业
 */
export function insertMajorbase(data) {
  return request({
    url: "/DataCenter/majorbase/Insert",
    method: "post",
    data
  });
}

/**
 *
 * @param {Form} params 修改专业
 */
export function updateMajorbase(data) {
  return request({
    url: "/DataCenter/majorbase/Update",
    method: "post",
    data
  });
}

/**
 *
 * @param {Form} params 删除专业
 */
export function deleteMajorbase(params) {
  return request({
    url: "/DataCenter/MajorBase/Delete",
    method: "post",
    params
  });
}

/**
 *
 * @param {Form} params 获取字典列表
 */
export function getDictionaryPageList(params) {
  return request({
    url: "/DataCenter/Dictionary/GetDictionaryPageList",
    method: "get",
    params
  });
}

/**
 *
 * @param {Form} params 获取字典详情
 */
export function getDic(params) {
  return request({
    url: "/DataCenter/Dictionary/GetDic",
    method: "get",
    params
  });
}

/**
 *
 * @param {Number} params {code:0} 通过字典的code查询字典内容
 */
export function getDicByCode(params) {
  return request({
    url: "/DataCenter/Dictionary/GetDicByCode",
    method: "get",
    params
  });
}

/**
 *
 * @param {Form} params 新增字典
 */
export function insertDic(data) {
  return request({
    url: "/DataCenter/Dictionary/InsertDic",
    method: "post",
    data
  });
}

/**
 *
 * @param {Form} params 更新字典
 */
export function updateDic(data) {
  return request({
    url: "/DataCenter/Dictionary/UpdateDic",
    method: "post",
    data
  });
}

/**
 *
 * @param {Form} params 删除字典
 */
export function deleteDic(params) {
  return request({
    url: "/DataCenter/Dictionary/DeleteDic",
    method: "post",
    params
  });
}

export function getMajorTree() {
  return request({
    url: "/DataCenter/MajorBase/GetMajorTree",
    method: "get"
  });
}

/**
 *
 * @param {Form} params 获取省市区的树
 */
export function getTreeArea(params) {
  return request({
    url: "/DataCenter/area/GetTree",
    method: "get",
    params
  });
}
