import request from "@/utils/request";
/** *****************************************学校相关api************************************************ */
/**
 * 获取学校信息 GET
 * @param {*} id
 */
export function getSchoolInfo(id) {
  return request({
    url: "/DataCenter/School/GetInfo?id=" + id,
    method: "get"
  });
}
/**
 * 获取学校列表 GET
 * @param {*} params
 */
export function getSchoolAllList(params) {
  return request({
    url: "/DataCenter/School/GetAllList",
    method: "get",
    params
  });
}
/**
 * 获取学校分页列表 GET
 * @param {*} params
 */
export function getSchoolPageList(params) {
  return request({
    url: "/DataCenter/School/GetPageList",
    method: "get",
    params
  });
}
/**
 * 获取学校类型 GET
 * @param {*} params
 */
export function getSchoolType(params) {
  return request({
    url: "/DataCenter/school/SchoolType",
    method: "get",
    params
  });
}

/**
 * 新增学校 POST
 * @param {*} data
 */
export function insertSchool(data) {
  return request({
    url: "/DataCenter/School/Insert",
    method: "post",
    data
  });
}
/**
 * 修改学校 POST
 * @param {*} data
 */
export function updateSchool(data) {
  return request({
    url: "/DataCenter/School/Update",
    method: "post",
    data
  });
}
/**
 * 删除学校 POST
 * @param {*} id
 */
export function deleteSchool(id) {
  return request({
    url: "/DataCenter/School/Delete?id=" + id,
    method: "post"
  });
}
/**
 * 批量删除学校 POST
 * @param {*} data
 */
export function batchSchoolDelete(data) {
  return request({
    url: "/DataCenter/School/BatchDelete",
    method: "post",
    data
  });
}
