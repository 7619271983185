<template>
    <div class="main-content" v-loading="loading">
        <div class="rc-header">
            <span class="rc-title"><i class="iconfont icon-jichushuju-nianji mr-8"></i>{{ headerTitle }}</span>
            <!-- <el-button v-if="isView" size="small" type="primary" icon="el-icon-edit" @click="isView=false">
        编辑
      </el-button> -->
        </div>
        <el-form ref="mainForm" class="rc-content" :model="nodeFormData" :rules="rules" label-width="100px">
            <el-form-item v-if="isCreate" prop="ParentName" label="所属上级：">
                <el-input placeholder="" v-model="nodeFormData.ParentName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="Name" label="组织名称：">
                <el-input placeholder="请输入组织名称" v-model="nodeFormData.Name" :readonly="isView"></el-input>
            </el-form-item>
            <el-form-item prop="Remark" label="备注说明：">
                <el-input type="textarea" :rows="5" placeholder="请输入备注说明" v-model="nodeFormData.Remark" :readonly="isView"></el-input>
            </el-form-item>
            <el-form-item v-if="!isView">
                <el-button type="primary" @click="handleSubmit">保 存</el-button>
                <el-button @click="isView = true">取 消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {
        getOrganizationInfo,
        insertOrganization,
        updateOrganization,
    } from "@/api/organization";
    export default {
        props: {
            nodeData: {
                type: Object,
                default () {
                    return { Id: "", TargetId: "" };
                },
            },
            newData: {
                type: Object,
                default () {
                    return { Level: 2, Sort: 1, TargetId: "" };
                },
            },
            isCreate: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isView: true,
                loading: false,
                classId: "",
                nodeFormData: {},
                rules: {
                    Name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
                },
                isEdit: false,
                schoolId: "",
                data: {},
                majorId: "",
            };
        },
        computed: {
            headerTitle() {
                var titleText = "组织信息";
                if (!this.isView) {
                    if (this.isCreate) {
                        titleText += " - 添加";
                    } else {
                        titleText += " - 修改";
                    }
                }
                return titleText;
            },
        },
        created() {
            this.initialize();
        },
        methods: {
            async initialize() {
                try {
                    this.loading = true;
                    this.isView = !this.isCreate;
                    console.log(this.isView);
                    debugger;
                    if (!this.isCreate) {
                        var res = await getOrganizationInfo(this.nodeData.Id);
                        if (res.IsSuccess) {
                            this.nodeFormData = res.Result;
                        } else {
                            this.$message.error("获取组织信息失败", res.Message);
                        }
                    } else {
                        this.nodeFormData = this.newData;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            },
            handleSubmit() {
                this.$refs.mainForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            var res;
                            this.nodeFormData = Object.assign(this.nodeFormData, {
                                SchoolId: this.schoolId,
                            });
                            if (this.isCreate) {
                                res = await insertOrganization(this.nodeFormData);
                                this.nodeFormData.Id = res.Result;
                            } else {
                                res = await updateOrganization(this.nodeFormData);
                            }
                            if (res.IsSuccess) {
                                this.$message.success("操作成功");
                                this.isView = true;
                                this.$emit("reload", this.nodeData);
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误",
                        });
                        return;
                    }
                });
            },
        },
        watch: {
            nodeData(val) {
                this.initialize();
            },
            isCreate() {
                this.initialize();
            },
        },
    };
</script>

<style></style>