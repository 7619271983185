<template>
    <div class="main-content" v-loading="loading">
        <div class="rc-header">
            <span class="rc-title"><i class="iconfont icon-jichushuju-erjiyuanxiao mr-8"></i>学院信息<span v-if="!isView">
                    - {{ isCreate ? "新增" : "修改" }}</span></span>
            <!-- <el-button
        v-if="isView"
        size="small"
        type="primary"
        icon="el-icon-edit"
        @click="isView = false"
      >
        编辑
      </el-button> -->
        </div>
        <el-form ref="collegeForm" :model="collegeFormData" :rules="collegeRules" label-width="100px">
            <el-form-item prop="Name" label="学院名称：">
                <el-input placeholder="请输入学院名称" v-model="collegeFormData.Name" size="large" :readonly="isView"></el-input>
            </el-form-item>
            <el-form-item prop="Introduction" label="学院简介：">
                <el-input type="textarea" :rows="5" placeholder="请输入学院简介" v-model="collegeFormData.Introduction" :readonly="isView"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="TypeId" label="联系电话:">
        <el-input
          placeholder="请输入联系电话"
          v-model="collegeFormData.Phone"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item prop="TypeId" label="邮箱：">
        <el-input
          placeholder="请输入邮箱"
          v-model="collegeFormData.Email"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item prop="TypeId" label="学院地址:">
        <el-input
          placeholder="请输入学院地址"
          v-model="collegeFormData.Address"
          size="large"
        ></el-input>
      </el-form-item> -->
            <el-form-item v-if="!isView">
                <el-button type="primary" @click="collegeSubmit">保 存</el-button>
                <el-button size="large" @click="isView = true">取 消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { insertCollege, updateCollege, getCollege } from "@/api/college";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    export default {
        props: {
            nodeData: {
                type: Object,
                default () {
                    return { Id: "", TargetId: "" };
                },
            },
            newData: {
                type: Object,
                default () {
                    return { Level: 2, Sort: 1, TargetId: "" };
                },
            },
            isCreate: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isView: true,
                loading: false,
                collegeFormData: {},
                collegeRules: {
                    Name: [{ required: true, message: "请输入学院名称", trigger: "blur" }],
                },
                nodeFormData: {},
            };
        },
        computed: {
            title() {
                if (this.isEdit) {
                    return "编辑学院信息";
                } else {
                    return "新增学院信息";
                }
            },
        },
        created() {
            this.initialize();
        },
        methods: {
            async initialize() {
                try {
                    this.loading = true;
                    this.isView = !this.isCreate;
                    console.log(this.isView);
                    if (!this.isCreate) {
                        var res = await getCollege(this.nodeData.TargetId);
                        console.log(99999, res);
                        if (res.IsSuccess) {
                            this.collegeFormData = res.Result;
                        } else {
                            this.$message.error("获取学院信息失败", res.Message);
                        }
                    } else {
                        this.collegeFormData = {
                            Name: "",
                            Introduction: "",
                            SchoolId: this.nodeData.UnitId,
                        };
                    }
                } catch (error) {
                    console.log(6, error);
                } finally {
                    this.loading = false;
                }
            },
            fillNodeForm(entityId) {
                if (this.isCreate) {
                    this.nodeFormData = Object.assign({}, this.newData);
                } else {
                    this.nodeFormData = this.nodeData;
                }
                this.nodeFormData.Name = this.collegeFormData.Name;
                this.nodeFormData.TargetId = entityId;
            },
            collegeSubmit() {
                this.$refs.collegeForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            var res;
                            if (this.isEdit) {
                                res = await updateCollege(this.collegeFormData);
                            } else {
                                res = await insertCollege(this.collegeFormData);
                            }
                            if (res.IsSuccess) {
                                if (this.isCreate) {
                                    this.fillNodeForm(res.Result);
                                    //新增学院后 新增节点关系
                                    var res2 = await insertOrganization(this.nodeFormData);
                                    if (res2.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.$emit("reload", this.nodeData);
                                    } else {
                                        this.$message.error(res2.Message || "操作失败");
                                    }
                                } else {
                                    this.fillNodeForm(this.collegeFormData.Id);
                                    var res3 = await updateOrganization(this.nodeFormData);
                                    if (res3.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.isView = true;
                                        this.$emit("reload", this.nodeData);
                                    } else {
                                        this.$message.error(res3.Message || "操作失败");
                                    }
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误",
                        });
                        return;
                    }
                });
            },
        },
        watch: {
            nodeData(val) {
                this.initialize();
            },
        },
    };
</script>

<style></style>