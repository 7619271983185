<template>
    <div class="main-content" v-loading="loading">
        <div class="rc-header">
            <span class="rc-title"><i class="iconfont icon-jichushuju-zhuanye mr-8"></i>{{ headerTitle }}</span>
            <!-- <el-button v-if="isView" size="small" type="primary" icon="el-icon-edit" @click="isView=false">
        编辑
      </el-button> -->
        </div>
        <el-form ref="majorForm" :model="majorFormData" class="rc-content" :rules="rules" label-width="100px">
            <el-row :gutter="12">
                <el-col :span="12">
                    <el-form-item prop="MajorName" label="专业名称：">
                        <el-input placeholder="请输入专业名称" v-model="majorFormData.MajorBase" size="large" :readonly="isView"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12"> </el-col>
            </el-row>

            <el-row :gutter="12">
                <el-col :span="12">
                    <el-form-item prop="LengthOfSchooling" label="学制：">
                        <el-input placeholder="请输入学制（如4年制、3年制）" v-model="majorFormData.LengthOfSchooling" size="large" :readonly="isView"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12"> </el-col>
            </el-row>

            <el-form-item v-if="!isView">
                <el-button type="primary" @click="handleSubmit">保 存</el-button>
                <el-button @click="isView = true">取 消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {
        insertMajor,
        updateMajor,
        getMajorInfo,
        getAchievementTypeList,
    } from "@/api/major";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    import RichText from "@/components/base/RichText";
    export default {
        props: {
            nodeData: {
                type: Object,
                default () {
                    return { Id: "", TargetId: "" };
                },
            },
            newData: {
                type: Object,
                default () {
                    return { Level: 2, Sort: 1, TargetId: "" };
                },
            },
            isCreate: {
                type: Boolean,
                default: false,
            },
        },
        components: { RichText },
        data() {
            return {
                isView: true,
                loading: false,
                majorFormData: {
                    Id: "",
                    MajorName: "",
                    SchoolId: "",
                    Introduction: "",
                    MajorBaseId: "",
                    LengthOfSchooling: 3,
                },
                rules: {
                    MajorBaseId: [
                        { required: true, message: "请选择专业", trigger: "blur" },
                    ],
                    MajorName: [
                        { required: true, message: "请输入专业名称", trigger: "blur" },
                    ],
                    LengthOfSchooling: [
                        { required: true, message: "请输入学制", trigger: "blur" },
                    ],
                },
                nodeFormData: {},
                MajorProps: {
                    label: "Name",
                    value: "Id",
                    children: "Children",
                    multiple: false,
                    expandTrigger: "hover",
                },
                majorOptions: [],
                achievementTypeOptions: [],
            };
        },
        computed: {
            headerTitle() {
                var titleText = "专业信息";
                if (!this.isView) {
                    if (this.isCreate) {
                        titleText += " - 添加";
                    } else {
                        titleText += " - 修改";
                    }
                }
                return titleText;
            },
        },
        created() {
            // if (!this.$store.getters.majorTree) {
            //   this.$store.dispatch("major/getMajorTree").then((res) => {
            //     this.majorOptions = res;
            //   });
            // } else {
            //   this.majorOptions = this.$store.getters.majorTree;
            // }
            this.initialize();
        },
        methods: {
            async initialize() {
                try {
                    this.loading = true;
                    this.isView = !this.isCreate;
                    console.log(this.isView);
                    var achievementTypeRes = await getAchievementTypeList(
                        this.nodeData.TargetId
                    );
                    this.achievementTypeOptions = achievementTypeRes.Result.Details;
                    if (!this.isCreate) {
                        var res = await getMajorInfo(this.nodeData.TargetId);
                        if (res.IsSuccess) {
                            console.log(res.Result);
                            this.majorFormData = res.Result;
                            // this.$refs.Introduction.setVal(this.majorFormData.Introduction);
                        } else {
                            this.$message.error("获取专业信息失败", res.Message);
                        }
                    } else {
                        this.majorFormData.SchoolId = this.nodeData.UnitId;
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            },
            fillNodeForm(entityId) {
                if (this.isCreate) {
                    this.nodeFormData = Object.assign({}, this.newData);
                } else {
                    this.nodeFormData = this.nodeData;
                }
                this.nodeFormData.Name = this.majorFormData.MajorName;
                this.nodeFormData.TargetId = entityId;
            },
            handleSubmit() {
                this.$refs.majorForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            var res;
                            console.log("1111", this.majorFormData.MajorBaseId.length);
                            debugger;
                            if (this.majorFormData.MajorBaseId.length != 36) {
                                this.majorFormData.MajorBaseId = this.majorFormData.MajorBaseId[2];
                            }
                            // this.majorFormData.Introduction = this.$refs.Introduction.getVal();
                            if (this.isCreate) {
                                res = await insertMajor(this.majorFormData);
                                this.majorFormData.Id = res.Result;
                            } else {
                                res = await updateMajor(this.majorFormData);
                            }
                            if (res.IsSuccess) {
                                var resOrg = {};
                                this.fillNodeForm(this.majorFormData.Id);
                                if (this.isCreate) {
                                    //新增专业后 新增节点关系
                                    resOrg = await insertOrganization(this.nodeFormData);
                                } else {
                                    resOrg = await updateOrganization(this.nodeFormData);
                                }
                                if (resOrg.IsSuccess) {
                                    this.$message.success("操作成功");
                                    this.isView = true;
                                    this.$emit("reload", this.nodeData);
                                } else {
                                    this.$message.error(resOrg.Message || "操作失败");
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误",
                        });
                        return;
                    }
                });
            },
            majorChange(val) {
                console.log("选择的基础专业", val);
                debugger;
                var selNode = this.$refs.majorCascader.getCheckedNodes(true);
                console.log("selNode", selNode);
                this.majorFormData.MajorName = selNode[0].label;
            },
        },
        watch: {
            nodeData(val) {
                this.initialize();
            },
        },
    };
</script>

<style></style>