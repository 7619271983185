import request from "@/utils/request";
import request_proxy from "@/utils/request-proxy";
/**
 * 获取全部班级
 * @param {*} id
 */
export function getClassList(id) {
  return request_proxy({
    url: "/DataCenter/ClassInfo/GetListBySchoolId?schoolId=" + id,
    method: "get"
  });
}
/**
 * 获取指定班级信息
 * @param {*} id
 */
export function getClassInfo(id) {
  return request_proxy({
    url: "/DataCenter/ClassInfo/GetInfo?id=" + id,
    method: "get"
  });
}

/**
 * 新增学院 POST
 * @param {*} data
 */
export function insertClass(data) {
  return request_proxy({
    url: "/DataCenter/ClassInfo/Insert",
    method: "post",
    data
  });
}
/**
 * 编辑学院 POST
 * @param {*} data
 */
export function updateClass(data) {
  return request_proxy({
    url: "/DataCenter/ClassInfo/Update",
    method: "post",
    data
  });
}
