<!--学院新增、编辑弹窗-->
<template>
    <el-dialog :title="title" :visible.sync="visible" append-to-body>
        <el-form ref="majorForm" :model="majorFormData" :rules="rules" label-width="100px">
            <el-form-item prop="MajorBaseId" label="基础专业：">
                <el-select v-model="majorFormData.MajorBaseId" filterable placeholder="请选择基础专业" style="width:100%">
                    <el-option v-for="item in majorList" :key="item.Id" :label="item.Name" :value="item.Id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="LengthOfSchooling" label="学制:">
                <el-input placeholder="请输入学制（如4年制、3年制）" v-model="majorFormData.LengthOfSchooling" size="large"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="large" @click="DialogCancel">取 消</el-button>
            <el-button type="primary" size="large" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { getAllList } from "@/api/majorBase";
    import { insertMajor, updateMajor, getMajorInfo } from "@/api/major";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    export default {
        data() {
            return {
                isEdit: false,
                visible: false,
                isloading: false,
                majorFormData: {
                    Id: "",
                    SchoolId: "",
                    LengthOfSchooling: 0,
                },
                majorList: [],
                rules: {
                    MajorBaseId: [
                        { required: true, message: "请选择专业", trigger: "blur" },
                    ],
                    LengthOfSchooling: [
                        { required: true, message: "请输入学制", trigger: "blur" },
                    ],
                },
                schoolId: "",
                data: {},
                nodeFormData: {},
            };
        },
        computed: {
            title() {
                if (this.isEdit) {
                    return "编辑专业";
                } else {
                    return "新增专业";
                }
            },
        },
        created() {
            this.initialize();
        },
        methods: {
            async initialize() {},
            async open(schoolId, data, isEdit) {
                console.log(schoolId, data, isEdit);
                if (!schoolId) {
                    this.$message.error("数据异常");
                }
                this.data = data;
                this.schoolId = schoolId;
                this.isEdit = isEdit;
                await this.getMajorList();
                await this.getInfo(data);
                this.visible = true;
            },
            async getInfo(data) {
                if (this.isEdit) {
                    var res = await getMajorInfo(data.TargetId);
                    if (res.IsSuccess) {
                        this.majorFormData = res.Result;
                    } else {
                        this.$message.error("获取专业信息失败", res.Message);
                    }
                }
            },
            async getMajorList() {
                this.isloading = true;
                const res = await getAllList();
                this.majorList = res.Result;
                this.isloading = false;
            },
            DialogSubmit() {
                this.$emit("DialogSubmit",this.nodeFormData);
                this.$refs.majorForm.resetFields();
                this.majorFormData = {};
                this.visible = false;
            },
            // DialogClose() {
            //     this.$refs.majorForm.resetFields();
            //     this.majorFormData = {};
            //     this.visible = false;
            //     this.$emit("DialogClosed");
            // },
            DialogCancel() {
                this.$refs.majorForm.resetFields();
                this.majorFormData = {};
                this.visible = false;
            },
            fillNodeForm(entityId) {
                var selectedMajor = this.majorList.filter((o) => {
                    return o.Id == this.majorFormData.MajorBaseId;
                });
                if (selectedMajor.length < 1) {
                    this.$message.error("基础专业信息查询出错");
                }
                this.nodeFormData = {
                    TargetId: entityId,
                    TypeId: "major",
                    Name: selectedMajor[0].Name,
                };
                if (this.isEdit) {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.ParentId,
                        Id: this.data.Id,
                    });
                } else {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.Id,
                    });
                }
            },
            submit() {
                this.$refs.majorForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.isloading = true;
                            var res;
                            this.majorFormData = Object.assign(this.majorFormData, {
                                SchoolId: this.schoolId,
                            });
                            if (this.isEdit) {
                                res = await updateMajor(this.majorFormData);
                            } else {
                                res = await insertMajor(this.majorFormData);
                            }
                            if (res.IsSuccess) {
                                if (!this.isEdit) {
                                    this.fillNodeForm(res.Result, this.data.Id);
                                    //新增专业后 新增节点关系
                                    var res2 = await insertOrganization(this.nodeFormData);
                                    if (res2.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res2.Message || "操作失败");
                                    }
                                } else {
                                    this.fillNodeForm(this.majorFormData.Id, this.data.Id);
                                    var res3 = await updateOrganization(this.nodeFormData);
                                    if (res3.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res3.Message || "操作失败");
                                    }
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.isloading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误",
                        });
                        return;
                    }
                });
            },
        },
    };
</script>

<style></style>