import request from "@/utils/request";
import request_proxy from "@/utils/request-proxy";
/**
 * 获取全部学院
 * @param {*} params
 */
export function getCollegeList(id) {
  return request_proxy({
    url: "/DataCenter/College/GetListBySchoolId?schoolId=" + id,
    method: "get"
  });
}
/**
 * 获取单个学院
 * @param {*} params
 */
export function getCollege(id) {
  return request_proxy({
    url: "/DataCenter/College/GetInfo?id=" + id,
    method: "get"
  });
}
/**
 * 新增学院 POST
 * @param {*} data
 */
export function insertCollege(data) {
  return request_proxy({
    url: "/DataCenter/College/Insert",
    method: "post",
    data
  });
}
/**
 * 编辑学院 POST
 * @param {*} data
 */
export function updateCollege(data) {
  return request_proxy({
    url: "/DataCenter/College/Update",
    method: "post",
    data
  });
}
