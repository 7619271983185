<!--学院新增、编辑弹窗-->
<template>
    <el-dialog :title="title" :visible.sync="visible" append-to-body>
        <el-form ref="collegeForm" :model="collegeFormData" :rules="collegeRules" label-width="100px">
            <el-form-item prop="TypeId" label="学院名称：">
                <el-input placeholder="请输入学院名称" v-model="collegeFormData.Name" size="large"></el-input>
            </el-form-item>
            <el-form-item prop="TypeId" label="联系电话:">
                <el-input placeholder="请输入联系电话" v-model="collegeFormData.Phone" size="large"></el-input>
            </el-form-item>
            <el-form-item prop="TypeId" label="邮箱：">
                <el-input placeholder="请输入邮箱" v-model="collegeFormData.Email" size="large"></el-input>
            </el-form-item>
            <el-form-item prop="TypeId" label="学院地址:">
                <el-input placeholder="请输入学院地址" v-model="collegeFormData.Address" size="large"></el-input>
            </el-form-item>
            <el-form-item prop="TypeId" label="学院简介:">
                <el-input placeholder="请输入学院简介" v-model="collegeFormData.Introduction" size="large"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="large" @click="DialogCancel">取 消</el-button>
            <el-button type="primary" size="large" @click="collegeSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { insertCollege, updateCollege, getCollege } from "@/api/college";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    export default {
        data() {
            return {
                isEdit: false, //新增 or 编辑
                schoolId: "", //学校ID
                visible: false,
                collegeFormData: {},
                collegeRules: {
                    Name: [{ required: true, message: "请输入学院名称", trigger: "blur" }]
                },
                data: {}, //节点， 新增时传入父节点，需要用data.Id作为新节点的父级ID， 编辑时传入当前节点，需要用到data.Id更新节点名
                nodeFormData: {}
            };
        },
        computed: {
            title() {
                if (this.isEdit) {
                    return "编辑学院信息";
                } else {
                    return "新增学院信息";
                }
            }
        },
        created() {
            this.initialize();
        },
        methods: {
            initialize() {},
            async open(schoolId, data, isEdit) {
                console.log(schoolId, data, isEdit);
                if (!schoolId) {
                    this.$message.error("数据异常");
                }
                this.data = data;
                this.schoolId = schoolId;
                this.isEdit = isEdit;
                await this.getInfo(data);
                this.visible = true;
            },
            async getInfo(data) {
                if (this.isEdit) {
                    var res = await getCollege(data.TargetId);
                    if (res.IsSuccess) {
                        this.collegeFormData = res.Result;
                    } else {
                        this.$message.error("获取学院信息失败", res.Message);
                    }
                }
            },
            DialogSubmit() {
                this.$emit("DialogSubmit", this.nodeFormData);
                this.$refs.collegeForm.resetFields();
                this.collegeFormData = {};
                this.visible = false;
            },
            DialogClose() {
                this.$refs.collegeForm.resetFields();
                this.collegeFormData = {};
                this.visible = false;
                this.$emit("DialogClosed");
            },
            DialogCancel() {
                this.$refs.collegeForm.resetFields();
                this.collegeFormData = {};
                this.visible = false;
            },
            fillNodeForm(entityId) {
                this.nodeFormData = {
                    TargetId: entityId,
                    TypeId: "college",
                    Name: this.collegeFormData.Name
                };
                if (this.isEdit) {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.ParentId,
                        Id: this.data.Id
                    });
                } else {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.Id
                    });
                }
            },
            collegeSubmit() {
                this.$refs.collegeForm.validate(async valid => {
                    if (valid) {
                        try {
                            this.isloading = true;
                            var res;
                            this.collegeFormData = Object.assign(this.collegeFormData, {
                                SchoolId: this.schoolId
                            });
                            if (this.isEdit) {
                                res = await updateCollege(this.collegeFormData);
                            } else {
                                res = await insertCollege(this.collegeFormData);
                            }
                            if (res.IsSuccess) {
                                if (!this.isEdit) {
                                    this.fillNodeForm(res.Result, this.data.Id);
                                    //新增学院后 新增节点关系
                                    var res2 = await insertOrganization(this.nodeFormData);
                                    if (res2.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res2.Message || "操作失败");
                                    }
                                } else {
                                    this.fillNodeForm(this.collegeFormData.Id, this.data.Id);
                                    var res3 = await updateOrganization(this.nodeFormData);
                                    if (res3.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res3.Message || "操作失败");
                                    }
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.isloading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误"
                        });
                        return;
                    }
                });
            }
        }
    };
</script>

<style></style>