<!--班级新增、编辑弹窗-->
<template>
    <el-dialog :title="title" :visible.sync="visible" append-to-body>
        <el-form ref="classForm" :model="classFormData" :rules="rules" label-width="100px">
            <el-form-item prop="Name" label="班级名称：">
                <el-input placeholder="请输入班级名称" v-model="classFormData.Name" size="large"></el-input>
            </el-form-item>
            <el-form-item prop="MajorId" label="所属专业:">
                <el-select disabled v-model="classFormData.MajorId" filterable placeholder="请选择所属专业" style="width:100%">
                    <el-option v-for="item in majorList" :key="item.Id" :label="item.MajorBase" :value="item.Id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="Grade" label="年级">
                <el-input placeholder="请输入年级" v-model="classFormData.Grade" size="large" type="number"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="large" @click="DialogCancel">取 消</el-button>
            <el-button type="primary" size="large" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { insertClass, updateClass, getClassInfo } from "@/api/classInfo";
    import { getMajorListBySchoolId } from "@/api/major";
    import { insertOrganization, updateOrganization } from "@/api/organization";
    export default {
        data() {
            return {
                visible: false,
                isloading: false,
                classId: "",
                majorList: [],
                classFormData: {},
                rules: {
                    Name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
                    MajorId: [{ required: true, message: "请选择专业", trigger: "blur" }]
                },
                isEdit: false,
                schoolId: "",
                data: {},
                nodeFormData: {},
                majorId: ""
            };
        },
        computed: {
            title() {
                if (this.isEdit) {
                    return "编辑班级";
                } else {
                    return "新增班级";
                }
            }
        },
        created() {
            this.initialize();
        },
        methods: {
            initialize() {},
            async open(schoolId, data, majorId, isEdit) {
                console.log(schoolId, data, majorId, isEdit);
                if (!schoolId) {
                    this.$message.error("数据异常");
                }
                this.data = data;
                this.schoolId = schoolId;
                this.isEdit = isEdit;
                try {
                    var res = await getMajorListBySchoolId(this.schoolId);
                    if (res.IsSuccess) {
                        this.majorList = res.Result;
                    }
                } catch (error) {
                    this.$message.error(error);
                }
                await this.getInfo(data, majorId);
                this.visible = true;
            },
            async getInfo(data, majorId) {
                if (this.isEdit) {
                    var res = await getClassInfo(data.TargetId);
                    if (res.IsSuccess) {
                        this.classFormData = res.Result;
                    } else {
                        this.$message.error("获取班级信息失败", res.Message);
                    }
                } else {
                    if (!majorId) {
                        this.$message.error("未能找到上级组织架构的专业，请自行选择专业");
                    } else {
                        this.classFormData = Object.assign(this.classFormData, {
                            MajorId: majorId
                        });
                    }
                }
            },
            DialogSubmit() {
                this.$emit("DialogSubmit", this.nodeFormData);
                this.$refs.classForm.resetFields();
                this.classFormData = {};
                this.visible = false;
            },
            DialogClose() {
                this.$refs.classForm.resetFields();
                this.classFormData = {};
                this.visible = false;
                this.$emit("DialogClosed");
            },
            DialogCancel() {
                this.$refs.classForm.resetFields();
                this.classFormData = {};
                this.visible = false;
            },
            fillNodeForm(entityId) {
                this.nodeFormData = {
                    TargetId: entityId,
                    TypeId: "class",
                    Name: this.classFormData.Name
                };
                if (this.isEdit) {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.ParentId,
                        Id: this.data.Id
                    });
                } else {
                    this.nodeFormData = Object.assign(this.nodeFormData, {
                        ParentId: this.data.Id
                    });
                }
            },
            submit() {
                this.$refs.classForm.validate(async valid => {
                    if (valid) {
                        try {
                            this.isloading = true;
                            var res;
                            this.classFormData = Object.assign(this.classFormData, {
                                SchoolId: this.schoolId
                            });
                            if (this.isEdit) {
                                res = await updateClass(this.classFormData);
                            } else {
                                res = await insertClass(this.classFormData);
                            }
                            if (res.IsSuccess) {
                                if (!this.isEdit) {
                                    this.fillNodeForm(res.Result, this.data.Id);
                                    //新增专业后 新增节点关系
                                    var res2 = await insertOrganization(this.nodeFormData);
                                    if (res2.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res2.Message || "操作失败");
                                    }
                                } else {
                                    this.fillNodeForm(this.classFormData.Id, this.data.Id);
                                    var res3 = await updateOrganization(this.nodeFormData);
                                    if (res3.IsSuccess) {
                                        this.$message.success("操作成功");
                                        this.DialogSubmit();
                                    } else {
                                        this.$message.error(res3.Message || "操作失败");
                                    }
                                }
                            } else {
                                this.$message.error(res.Message || "操作失败");
                            }
                        } catch (err) {
                            console.log(err);
                        } finally {
                            this.isloading = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "表单填写错误"
                        });
                        return;
                    }
                });
            }
        }
    };
</script>

<style></style>